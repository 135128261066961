import Head from "next/head";

export default function Home() {
  return (
    <>
      <Head>
        <title>Reskinned | Home</title>
      </Head>
      <h1>Reskinned Frontend</h1>
    </>
  );
}
